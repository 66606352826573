import React, { useState, useRef, useEffect } from 'react';
import validator from 'validator';


const Contact = () => {


  const [inputValue, setInputValue] = useState("");
  const [displayText, setDisplayText] = useState([
    { text: "Connected to server...", color: "text-green-500" },
    "What is your name?"
  ]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [stage, setStage] = useState(0);
  const [messageSent, setMessageSent] = useState(false);
  const [isSendingProcessComplete, setIsSendingProcessComplete] = useState(false);

  const displayRef = useRef(null);
  
  const handleButtonClick = () => {
    handleEnter();
  }

  useEffect(() => {
    if (displayRef.current) {
      displayRef.current.scrollTop = displayRef.current.scrollHeight;
    }
  }, [displayText]);

  const sendSequence = [
    { text: "[INFO] Initializing connection to server...", color: "text-blue-500" },
    { text: "[SUCCESS] Connection established.", color: "text-green-500" },
    { text: "[INFO] Creating a new message object...", color: "text-blue-500" },
    { text: `Message {\n  name: "${name}",\n  email: "${email}",\n  content: "${message}"\n}`, color: "text-yellow-400" },
    { text: "[INFO] Serializing message object...", color: "text-blue-500" },
    { text: "[INFO] Encoded message to Base64 format.", color: "text-blue-500" },
    { text: "[INFO] Establishing secure TLS handshake...", color: "text-blue-500" },
    { text: "[SUCCESS] Handshake completed.", color: "text-green-500" },
    { text: "[INFO] Sending message payload to server...", color: "text-blue-500" },
    { text: "[INFO] Waiting for server acknowledgment...", color: "text-blue-500" }
  ];

  const handleEnter = () => {

    if (!inputValue.trim()) {
      setDisplayText([...displayText, "Input cannot be empty. Please try again."]);
      setInputValue("");
      return;
    }

    if (stage === 0) {
      setName(inputValue);
      setDisplayText([...displayText, "What is your email:"]);
      setInputValue("");
      setStage(1);
    } else if (stage === 1) {

      if (!validator.isEmail(inputValue.trim())) {
        setDisplayText([...displayText, "Invalid email format. Please try again."]);
        setInputValue("");
        return;
        
      }
      setEmail(inputValue);
      setDisplayText([...displayText, "Please enter your message:"]);
      setInputValue("");
      setStage(2);
    } else if (stage === 2) {
      setMessage(inputValue);
      setDisplayText([...displayText,
      { text: `Name: ${name}`, color: "text-yellow-400" },
      { text: `Email: ${email}`, color: "text-yellow-400" },
      { text: `Message: ${inputValue}`, color: "text-yellow-400" },
        "",
        "Are you ready to send the message? (yes/no)"]);
      setInputValue("");
      setStage(3);
    }
    else if (stage === 3) {
      if (inputValue.toLowerCase() === "yes") {
        setMessageSent(true);
        setDisplayText(["Sending...", ""]);
        setInputValue("");

        let step = 0;
        const displaySendSteps = () => {
          if (step < sendSequence.length) {
            setDisplayText(prev => [...prev.slice(0, -1), sendSequence[step], ""]);
            step++;
            setTimeout(displaySendSteps, 500);
          } else {
            setDisplayText(prev => prev.slice(0, -1));
            setIsSendingProcessComplete(true);
          }
        }

        setTimeout(displaySendSteps, 1000);
        setInputValue("");

        // Connect to the server after completing the display sequence
        setTimeout(() => {
          const sendData = async () => {
            try {
              const response = await fetch("/.netlify/functions/contact", {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({ name, email, message })
              });

              if (!response.ok) {
                throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
              }


              const data = await response.json();

              if (data.status === 'Message Sent') {
                if (!isSendingProcessComplete) {
                  // Append the remaining steps
                  for (; step < sendSequence.length; step++) {
                    setDisplayText(prev => [...prev, sendSequence[step]]);
                  }
                }
                setDisplayText([...displayText, { text: "[SUCCESS] Message sent successfully!", color: "text-green-500" }]);
              } else {
                // Handling failure
                setDisplayText([...displayText, `There was an issue sending the message: ${data.message}. Please try again later.`]);
              }
            } catch (error) {
              console.error("There was an error sending the message", error);
              setDisplayText(prev => [...prev, { text: "There was an error sending the message. Please check your connection and try again.", color: "text-red-500"}]);
            }
          };

          sendData();
        }, (sendSequence.length + 2) * 500); // This timeout ensures sendData is called after the display sequence is complete

      } else if (inputValue.toLowerCase() === "no") {
        setDisplayText([ { text: "Connected to server...", color: "text-green-500" },
        "What is your name?"]);
        setStage(0);
        setInputValue("");
      }
      else {
        setDisplayText([...displayText, "Incorrect command. Are you ready to send the message? (yes/no)"]);
        setInputValue("");
      }
    }
  };

  return (
    <>
      <div className="md:hidden h-[64px] mt-[-64px]"></div>
  
      <div id='contact' className="flex justify-center items-center min-h-screen bg-black p-10" style={{ scrollMarginTop: "64px" }}>
        <div className="box-container w-full h-full md:w-3/4 md:min-w-[300px] max-w-4xl min-w-xs bg-gray-200 p-6 rounded-lg shadow-xl md:p-8 "
          style={{
            maxWidth: '80vw',
            maxHeight: '80vh',
            fontFamily: "Courier New, monospace",
            fontWeight: 700,
            overflowY: 'auto'
          }}>
          <div className="flex justify-between items-center mb-8 border-b-2 border-gray-400 pb-6">
            <h1 className="text-2xl font-bold text-gray-900 md:text-4xl" style={{ fontFamily: "Courier New, monospace", fontWeight: 700 }}>
              Contact Me
            </h1>
            <a href="mailto:contact@mfazeel.com" target="_blank" rel="noopener noreferrer" className="text-gray-900  hover:text-green-500 transition-colors duration-300">
              <i className="fas fa-envelope fa-2x"></i>
            </a>
          </div>
          <div className="bg-gray-200 p-2 md:p-4 rounded sm:max-h-[30vh] md:max-h-[48vh]" style={{ minHeight: "30vh", maxHeight: "30vh", overflowY: "auto", scrollBehavior: 'smooth', fontFamily: "Courier New, monospace", fontWeight: 700 }} ref={displayRef}>
            {displayText.map((text, index) => {
              let content = text;
              let textColor = "text-gray-900";  // default color
  
              if (typeof text === "object") {
                textColor = text.color;
                // Handle newlines
                content = text.text.split('\n').map((line, i, arr) => {
                  return i === arr.length - 1 ? line : <>
                    {line}
                    <br />
                  </>
                });
              }
  
              return (
                <p key={index} className={textColor}>
                  {content}
                </p>
              );
            })}
          </div>
          {!messageSent && (
            <div className="flex mt-4 w-full">
              {/* <span className="mr-4 text-green-500 blink">></span> */}
              <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} onKeyDown={(e) => e.key === "Enter" && handleEnter()} className="flex-grow p-1 rounded border" style={{ fontWeight: 700 }} />
              <button onClick={handleButtonClick} className="ml-2 bg-green-500 text-white p-2 rounded border hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500">Send</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
  
  export default Contact;