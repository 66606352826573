import React from 'react';

const Footer = () => {
    return (
        <footer className="w-full bg-black text-white text-center p-4 ">
            &copy; {new Date().getFullYear()} MMF. All rights reserved.
        </footer>
    );
};

export default Footer;
