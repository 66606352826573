import React from 'react';
import { FaReact, FaNodeJs, FaCss3Alt, FaJsSquare } from 'react-icons/fa';
import { SiDjango } from 'react-icons/si';
import { FaSass, FaGithub } from 'react-icons/fa';
import { SiMongodb } from "react-icons/si";
import { FaHtml5, FaPython } from 'react-icons/fa';
import { IoLogoFirebase } from 'react-icons/io5'; 
import { SiTailwindcss, SiNumpy } from "react-icons/si";
import { SiOpencv } from "react-icons/si";
import { BiLogoNetlify } from 'react-icons/bi';

import projectGif1 from '../assets/project1.png';
import projectGif2 from '../assets/project2.png';
import projectGif3 from '../assets/project3.gif';
import project1mac from '../assets/project1_mockup.png';
import project1iphone from '../assets/project1_iphone.png';
import portfolio_mac from '../assets/portfolio_old_mac.png';
import project_vision from '../assets/project_vision_mac.png';

const TechnologyIcon = ({ tech }) => {
  const techMapping = {
    'React': <FaReact size={24} className='text-custom-react-600'/>,
    'Node.js': <FaNodeJs size={24} className='text-custom-node-600'/>,
    'CSS3': <FaCss3Alt size={24} className='text-custom-css-600'/>,
    'JavaScript': <FaJsSquare size={24} className='text-custom-javascript-600'/>,
    'Django': <SiDjango size={24} />,
    'Firebase': <IoLogoFirebase size={24} className='text-custom-firebase-600' />,
    'Sass': <FaSass size={24} className='text-custom-sass-600' />,
    'HTML': <FaHtml5 size={24} className='text-custom-html-600' />,
    'MongoDB': <SiMongodb size={24} className='text-custom-MongoDB-600' />,
    'Tailwind': <SiTailwindcss size={24} className='text-custom-Tailwind-600' />,
    'OpenCV': <SiOpencv size={24} className='text-custom-OpenCV-600' />,
    'Python': <FaPython size={24} className='text-custom-python-600' />,
    'NumPy': <SiNumpy size={24} className='text-custom-numpy-600' />,
    'Netlify': <BiLogoNetlify size={24} className='text-custom-Netlify-600' />,


  };

  return techMapping[tech] || null;
}

const ProjectItem = ({ project }) => {
  return (
    <div className="flex flex-col md:flex-row md:items-center bg-white p-4 rounded-lg hover:shadow-lg transition-shadow duration-300">
      <div className="md:flex-shrink-0 w-full md:w-1/2">
        <img src={project.gif} alt={`Mockup of ${project.name}`} className="rounded-lg w-full h-auto mb-4 md:mb-0" />
      </div>
      <div className="md:pl-4 flex-grow">
        <div className="flex justify-between items-center mb-2">
          <h4 className="text-2xl font-bold">{project.name}</h4>
          <a href={project.repoLink} target="_blank" rel="noopener noreferrer" className="text-sm font-medium text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out sm:text-sm">
            View Project &rarr;
          </a>
        </div>
        <p className="mb-4">{project.description}</p>
        <div className="mt-4">
          <h5 className="text-xl font-bold mb-2">{project.subheading}</h5>
          <p>{project.subheadingDescription}</p>
        </div>
        <div className="flex space-x-3 mb-4 pt-6">
          {project.technologies.map(tech => (
            <TechnologyIcon key={tech} tech={tech} />
          ))}
        </div>
      </div>
    </div>
  );
};


const Projects = () => {
  const projectData = [
    // {
    //   gif: projectGif1,
    //   name: "Old Portfolio Website",
    //   description: "My earlier portfolio reflects a pivotal chapter in my journey as a developer. A testament to my evolving craftsmanship, it offers a glimpse into the growth and refinement of my design and coding sensibilities over the years.",
    //   technologies: ['React', 'Node.js','Sass', 'JavaScript'],
    //   repoLink: "https://frolicking-licorice-505222.netlify.app/"
    // },
    {
      gif: project1mac,
      name: "TestFlow Analysis Dashboard ",
      description: "The TestFlow Analysis Dashboard, hosted on GitHub, is a sophisticated tool designed for software testing and workflow analysis. It offers dynamic data visualization and real-time monitoring capabilities, resulting in a significant enhancement in operational efficiency and user engagement.",
      technologies: ['JavaScript','Node.js', 'React', 'MongoDB','HTML', 'Tailwind', ],
      repoLink: "https://github.com/m-fazeel/automated_testing_dashboard",
      subheading: "Technology",
      subheadingDescription: "The dashboard is built using a combination of JavaScript, React.js, Express, and Node.js, with MongoDB for data management. The user interface is enhanced by Material-UI and Tailwind CSS, while Recharts enable dynamic data visualization. Real-time capabilities are achieved through GitHub Actions, webhooks, and WebSockets, contributing to its efficiency and responsiveness."
    },
    {
      gif: portfolio_mac,
      name: "Portfolio Website",
      description: "This portfolio is a key representation of my evolution as a developer, illustrating the significant advancements in my design and coding abilities. It's a collection that captures the essence of my journey, demonstrating the progressive refinement of my technical skills and creative approach",
      technologies: ['HTML', "CSS3", 'JavaScript', 'React', 'Sass', 'Netlify'],
      repoLink: "https://frolicking-licorice-505222.netlify.app/",
      subheading: "Technology",
      subheadingDescription: "This portfolio was constructed using React for the front-end, paired with Material UI for a sleek user interface. The back-end relies on Node and Express, while SaaS and JavaScript were used to enhance the styling and functionality, ensuring a robust and responsive experience."
    },

    {
      gif: project_vision,
      name: "Advanced Motion Detection",
      description: "This project involves a sophisticated motion detection system embedded within a video player application. It's designed to analyze video frames and detect motion using advanced image processing techniques. The system can track multiple objects simultaneously and utilizes a Kalman Filter for accurate prediction and updating of object positions.",
      technologies: [ 'Python','OpenCV', 'NumPy', 'SciKit-Video', 'Tkinter', 'PIL'],
      repoLink: "https://github.com/m-fazeel/advanced_motion_detection",
      subheading: "Technology",
      subheadingDescription: "This project integrates Python with OpenCV for image processing, NumPy for data handling, and SciKit-Video for video management. Tkinter and PIL create the user interface, while collections.deque tracks object movements, enabling efficient motion detection and tracking in a streamlined application."
    }
  ]

  return (
    <>
      <div className="md:hidden h-[64px] mt-[-64px]"></div>
      <div id="projects" className="min-h-screen bg-gray-200 p-10 md:p-16 lg:p-20 xl:p-24 shadow-ug" style={{ scrollMarginTop: "64px" }}>
        <h1 className="text-4xl font-bold text-center mb-12">My Projects</h1>
        <div className="grid grid-cols-1 gap-4">
          {projectData.map((project, idx) => (
            <ProjectItem key={idx} project={project} />
          ))}
        </div>

        <div className="text-center mt-8">
        <a href="https://github.com/m-fazeel" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center hover:text-darkblue-600 hover:underline">
          <FaGithub size={24} className="mr-2 text-custom-Github-600" />
          <span className="text-sm">See more on my GitHub</span>
        </a>
      </div>
      </div>
    </>
  );
};
export default Projects;
