import React from 'react'
import { FaLinkedin, FaGithub, FaReact, FaNodeJs, FaJsSquare, FaHtml5 } from 'react-icons/fa';
import { SiTailwindcss } from 'react-icons/si';
import { BiLogoNetlify } from 'react-icons/bi';
import myPic from '../assets/mypic.JPG';


const Home = () => {
    return (
        <div className='fade-in-animation'>
             <div className="md:hidden h-[64px] mt-[-64px]"></div>
            <div id='home' className="flex flex-col justify-center items-center min-h-screen bg-gray-100 p-8" style={{ scrollMarginTop: "64px" }} >
                {/* Top Section - Image and Text side by side */}
                <div className="flex flex-col md:flex-row justify-center items-center w-full space-y-0 sm:space-y-8 md:space-y-0 md:space-x-20">
                    {/* Left Side - Image */}
                    <div className="shadow-xl mb-8 md:mb-0">
                        <img src={myPic} alt="Fazeel" className="w-64 h-90 mx-auto md:mx-0" />
                    </div>

                    {/* Right Side - Text */}
                    <div>
                        <h1 className="text-4xl sm:text-6xl mb-4 font-bold">Software Developer</h1>
                        <h2 className="text-xl sm:text-2xl mb-4">Hi, I'm Fazeel. Crafting digital masterpieces with each sip of coffee.</h2>
                        <div className="flex space-x-4 mb-4">
                            <a href="https://linkedin.com/in/mfazeel" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin size={32} color='#2366C2' />
                            </a>
                            <a href="https://github.com/m-fazeel" target="_blank" rel="noopener noreferrer">
                                <FaGithub size={32} color='' />
                            </a>
                        </div>
                    </div>
                </div>

                {/* Tech Stack - Centered below image and text */}
                <div className="w-full mt-10 text-center">
                    <h3 className="text-3xl mb-4">Tech Stack</h3>
                    <div className="flex flex-wrap justify-center md:space-x-4 sm:space-y-4 sm:space-x-0 md:space-y-0 items-center space-x-2">
                        <div className="flex items-center space-x-2 mb-0 sm:mb-0">
                            <FaHtml5 size={32} className='text-custom-html-600' />
                            <span>HTML5</span>
                        </div>
                        <div className="flex items-center space-x-2 mb-0 sm:mb-0">
                            <FaJsSquare size={32} className='text-custom-javascript-600' />
                            <span>JavaScript</span>
                        </div>
                        <div className="flex items-center space-x-2 mb-2 sm:mb-0">
                            <FaReact size={32} className='text-custom-react-600' />
                            <span>React</span>
                        </div>
                        <div className="flex items-center space-x-2 mb-2 sm:mb-0">
                            <FaNodeJs size={32} className='text-custom-node-600' />
                            <span>Node.js</span>
                        </div>
                        <div className="flex items-center space-x-2 mb-2 sm:mb-0">
                            <SiTailwindcss size={32} className='text-custom-Tailwind-600' />
                            <span>Taillwind</span>
                        </div>
                        <div className="flex items-center space-x-2 mb-2 sm:mb-0">
                            <BiLogoNetlify size={32} className='text-custom-Netlify-600' />
                            <span>Netlify</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;