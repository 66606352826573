import React from 'react';
import companyLogo1 from '../assets/companyLogo1.png';
import companyLogo2 from '../assets/companyLogo2.png';
import companyLogo3 from '../assets/capitalOne.png';
import goTogetherLogo from '../assets/goTogetherLogo.png';

const Experience = () => {
  return (
    <>
      <div className="md:hidden h-[64px] mt-[-64px]"></div>

      <div id="experience" className="flex flex-col min-h-screen bg-gray-100 p-10 md:p-16 lg:p-20 xl:p-24 justify-center items-center  shadow-xl border-b border-gray-300 shadow-md" style={{ scrollMarginTop: "64px" }}>


        <h1 className="text-4xl font-bold mb-8 md:mb-10 lg:mb-12 xl:mb-16 experience-heading" >My Experience</h1>
        <div className="w-full max-w-5xl p-10 bg-white rounded-lg shadow-xl space-y-10 ">
          
          {/* GoTogether Experience */}
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
            <div className="flex items-center md:w-1/3 mb-4">
              <img src={goTogetherLogo} alt="Company Logo 1" className="w-16 h-16" />
              <h4 className="ml-4 text-xl font-semibold">Go Together</h4>
            </div>

            <div className="md:w-2/3">
              <h5 className="text-right text-lg text-black-600 mb-2">Software Developer Intern</h5>
              <div className="text-right mb-4">
                <p>Oct 2023 - December 2023</p>
              </div>
              <p>Responsible for the creation and maintenance of documentation for both Android and iOS applications, developed using Java, .NET, and Swift. Led the integration and architecture restructuring of the app codebase, enabling efficient updates and bug resolutions which involved management of version control and testing methodologies.</p>
            </div>
          </div>
          <hr className="my-8" />

          {/* Experience 1 */}
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
            <div className="flex items-center md:w-1/3 mb-4">
              <img src={companyLogo1} alt="Company Logo 1" className="w-16 h-16" />
              <h4 className="ml-4 text-xl font-semibold">Office of Information Technology, UTA</h4>
            </div>

            <div className="md:w-2/3">
              <h5 className="text-right text-lg text-black-600 mb-2">Technology Analyst</h5>
              <div className="text-right mb-4">
                <p>Aug, 2021 - May 2024</p>
              </div>
              <p>Deliver end-to-end technical support services to maintain optimal performance and reliability of <span className="font-bold">215 computers</span> spread across <span className="font-bold">13 computer labs</span> by performing hardware and software repairs, conducting regular technical evaluations, and managing server network systems, ensuring optimal performance and uninterrupted operations.</p>
            </div>
          </div>
          <hr className="my-8" />

           {/* Experience */}

           <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
            <div className="flex items-center md:w-1/3 mb-4">
              <img src={companyLogo3} alt="Company Logo 4" className="w-20 h-16" />
              <h4 className="ml-4 text-xl font-semibold">Capital One</h4>
            </div>

            <div className="md:w-2/3">
              <h5 className="text-right text-lg text-black-600 mb-2">Developer - Extern</h5>
              <div className="text-right mb-4">
                <p>July 2023</p>
              </div>
              <p>Selected as one of <span className="font-bold">50 participants</span> from a vast applicant group, I spearheaded the creation of a bespoke university credit card product during a case study challenge. Working closely with a cross-functional team, we allocated tasks based on individual strengths and successfully presented our project to <span className="font-bold">Capital One</span> professionals.</p>
            </div>
          </div>
          <hr className="my-8" />

          {/* Experience 2 */}
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">

            <div className="flex items-center md:w-1/3 mb-4">
              <img src={companyLogo2} alt="Company Logo 1" className="w-18 h-16" />
              <h4 className="ml-4 text-xl font-semibold">Texas Health Resources</h4>
            </div>

            <div className="md:w-2/3">
              <h5 className="text-right text-lg text-black-600 mb-2">Student Researcher</h5>
              <div className="text-right mb-4">
                <p>Jun, 2023 - Jul, 2023</p>
              </div>
              <p>Conducted a thorough review of technologies across <span className='font-bold'>59</span> departments to ensure top-notch functionality and ease of use by leveraging software engineering and biomedical technology expertise. Led the brainstorming and assessment of senior year project ideas, closely examining their feasibility, cost-efficiency, and potential risks to guarantee impactful results.</p>
            </div>

          </div>
          <hr className="my-8" />

          {/* Experience 3 */}
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">

            <div className="flex items-center md:w-1/3 mb-4">
              <img src={companyLogo1} alt="Company Logo 1" className="w-16 h-16" />
              <h4 className="ml-4 text-xl font-semibold">UTA Bookstore</h4>
            </div>

            <div className="md:w-2/3">
              <h5 className="text-right text-lg text-black-600 mb-2">Customer Service Representative</h5>
              <div className="text-right mb-4">
                <p>Jun, 2021 - Aug, 2021</p>
              </div>
              <p>Achieved significant improvements in customer service by reducing new customer wait time by <span className='font-bold'>20%</span>, increasing customer service rating from <span className='font-bold'>3.7 to 3.9</span>, and fostering strong relationships of trust through open communication. Handled a high volume of more than <span className='font-bold'>60</span> daily inbound calls, efficiently assisting customers with order placement and refunds, leading to increased customer satisfaction and loyalty.</p>
            </div>
          </div>

        
        </div>
      </div>
    </>
  );
}

export default Experience;