import React, { useEffect, useRef } from 'react';
import AOS from 'aos';

const SkillCategory = ({ title, skills }) => (
  <div>
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <ul className="list-disc list-inside">
      {skills.map(skill => (
        <li key={skill} className="text-md text-gray-700">{skill}</li>
      ))}
    </ul>
  </div>
);

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const programmingSkills = ['Python', 'C++', 'Java', 'C#', 'C', 'JavaScript', 'TypeScript'];
  const frameworksAndLibraries = ['React', 'Angular', 'Express.js', 'Node.js', 'TailwindsCSS', '.NET'];
  const tools = [
    'Linux', // Operating System
    'Git', // Version Control
    'Github', // Version Control Hosting
    'Docker', // Containerization
    'AWS', // Cloud Service
    'Google Cloud', // Cloud Service
    'Firebase', // Backend Service
    'Jenkins', // Continuous Integration
    'Figma', // Design Tool
  ];
  const databases = ['SQL', 'PostgreSQL', 'MongoDB', 'GraphQL'];

  return (
    <>
      <div id='about' className="justify-center items-center min-h-screen bg-white p-10" style={{ scrollMarginTop: "64px", fontFamily: 'PT mono, monospace' }} >
        {/* Heading */}
        <h1 className="text-4xl font-bold text-gray-900 mb-8 border-b-2 border-gray-300 pb-4 text-center ">About Me</h1>
        {/* Two Columns */}
        <div className="flex flex-col md:flex-row justify-center inline-block align-middle">
          {/* Left Column */}
          <div className="md:w-1/2 md:pr-5 flex items-center justify-center ">
            <div className="bg-white-800 md:p-8 rounded-lg sm:p-3">
              <p className="text-green-400 text-sm">&lt;body&gt;</p>
              <p className="text-blue-400 text-sm ml-4">&lt;h1&gt;Hello World!&lt;/h1&gt;</p>
              <p className="text-blue-400 text-sm ml-4">&lt;p&gt;</p>
              <p className="text-black-300 md:text-xl ml-8 pt-3 pb-3 sm:text-xl">
                I'm a <span className='font-bold'>Full Stack Developer</span> with a bachelor's degree in <span className='font-bold'>Computer Science</span>. I specialize in building both the front and back ends of websites and applications, focusing on creating successful and effective products. Take a look at my <a href='#projects'><span className='font-bold underline underline-offset-4'>project</span></a> work to see my skills in action.
                <br /><br />
                I'm currently seeking <span className='font-bold'>job opportunities</span> where I can contribute and continue to grow. If you have a role that fits my skills, I'd love to hear from you!
              </p>
              <p className="text-blue-400 text-sm ml-4">&lt;/p&gt;</p>
              <p className="text-green-400 text-sm">&lt;/body&gt;</p>
            </div>
          </div>

          {/* Right Column */}
          <div className="md:w-1/2 md:pl-5 p-6">
            <h2 className="text-2xl font-bold mb-5 ">My Skills</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <SkillCategory title="Programming" skills={programmingSkills} />
              <SkillCategory title="Tools and Platforms" skills={tools} />
              <SkillCategory title="Frameworks & Libraries" skills={frameworksAndLibraries} />
              <SkillCategory title="Databases and Query Languages" skills={databases} />

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
