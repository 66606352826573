// components/Navbar.js
import React, { useState, useEffect } from 'react';

const Navbar = () => {
  const [isNavbarOpen, setNavbarOpen] = useState(false); // Added this line to manage navbar states
  const [activeSection, setActiveSection] = useState("home");
  const GreenDot = () => (
    <span className="bg-green-500 w-1 h-1 rounded-full absolute top-0 transform -translate-y-2 left-1/2 translate-x-1/2 hidden md:inline-block"></span>
  );

  useEffect(() => {
    // Define the callback for the observer
    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    // Create the observer with a threshold of 0.5
    const observer = new IntersectionObserver(observerCallback, { threshold: 0.5 });

    // Get all sections and start observing them
    const sections = document.querySelectorAll('div[id]');
    sections.forEach(section => observer.observe(section));

    // Cleanup
    return () => sections.forEach(section => observer.unobserve(section));
  }, []);


  const handleClick = (section) => {
    setActiveSection(section);
    setNavbarOpen(false); // Added this line to close the navbar when a link is clicked
  };


  return (
    <nav className="bg-white border-black-200 dark:bg-gray-900 border-b border-gray-200 shadow-lg sticky top-0 z-50">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="#" className="flex items-center">
          <span className="self-center text-4xl font-bold whitespace-nowrap dark:text-white">MMF</span>
        </a>
        <button
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-expanded={isNavbarOpen}
          onClick={() => setNavbarOpen(!isNavbarOpen)}
        >
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
        <div className={`${isNavbarOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`}>
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <a href="#home" onClick={() => handleClick('home')} className={`relative block py-2 pl-3 pr-4  ${activeSection === 'home' ? 'text-blue-700 bg-blue-700' : 'text-gray-900 hover:bg-gray-100'}rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>{activeSection === 'home' && <GreenDot />}Home</a>
            </li>
            <li>
              <a href="#about" onClick={() => handleClick('about')} className={`relative block py-2 pl-3 pr-4  ${activeSection === 'about' ? 'text-blue-700 bg-blue-700' : 'text-gray-900 hover:bg-gray-100'}rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>{activeSection === 'about' && <GreenDot />}About</a>
            </li>
            <li>
              <a href="#experience" onClick={() => handleClick('experience')} className={`relative block py-2 pl-3 pr-4  ${activeSection === 'experience' ? 'text-blue-700 bg-blue-700' : 'text-gray-900 hover:bg-gray-100'}rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>{activeSection === 'experience' && <GreenDot />}Experience</a>
            </li>
            <li>
              <a href="#certifications" onClick={() => handleClick('certifications')} className={`relative block py-2 pl-3 pr-4  ${activeSection === 'certifications' ? 'text-blue-700 bg-blue-700' : 'text-gray-900 hover:bg-gray-100'}rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>{activeSection === 'certifications' && <GreenDot />}Certifications</a>
            </li>
            <li>
              <a href="#projects" onClick={() => handleClick('projects')} className={`relative block py-2 pl-3 pr-4  ${activeSection === 'projects' ? 'text-blue-700 bg-blue-700' : 'text-gray-900 hover:bg-gray-100'}rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>{activeSection === 'projects' && <GreenDot />}Projects</a>
            </li>
            <li>
              <a href="#contact" onClick={() => handleClick('contact')} className={`relative block py-2 pl-3 pr-4  ${activeSection === 'contact' ? 'text-blue-700 bg-blue-700' : 'text-gray-900 hover:bg-gray-100'}rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>{activeSection === 'contact' && <GreenDot />}Contact</a>
            </li>
            
            <li>
              <a href="https://resume.mfazeel.com" target="_blank" onClick={() => handleClick('resume')} className={`relative block py-2 pl-3 pr-4 text-gray-900 hover:bg-gray-100 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>Resume</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
